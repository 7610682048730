<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Liste des utilisateurs</h3>
        </div>
        <div class="card-toolbar">
          <a v-b-tooltip.hover title="Ajouter un utilisateur" class="btn btn-icon btn-sm btn-success mr-1"  href="/#/user/nouveau">
            <i class="icon-md fas fa-plus"></i>
          </a>
          <a  v-b-tooltip.hover title="Recherchez" class="btn btn-icon btn-sm btn-danger mr-1"  v-b-modal.search>
            <i class="icon-md fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="1">
            <select class="form-control" v-model.number="datatable.limitPage" @change="datatableInit">
              <option value="1">1</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </b-col>
        </b-row>
        <b-table responsive striped hover ref="table" :items="datatable.data" :fields="fields" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
          <template #cell(nom)="data">
            <a :href="'/#/user/detail/'+data.item.id">{{ data.value }}</a>
          </template>
          <template #cell(role_id)="data">
            {{$store.back.getParamTitre(params.UserRole,data.value)}}
          </template>
          <template #cell(status)="data">
            {{params.status.find(p => {return p.id == data.value}).titre}}
          </template>
          <template #cell(date_create)="data">
            {{$store.back.timestampToDate(data.value)}}
          </template>
        </b-table>
        <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
      </div>
    </div>
    <b-modal id="search" title="Recherche" size="xl" ref="myModalDeleteRef">
      <div class="row">
        <div class="form-group col-md-4">
          <label>ID</label>
          <input type="text" placeholder="Rechercher par ID" class="form-control" v-model.number="filters.id">
        </div>
        <div class="form-group col-md-4">
          <label>Nom</label>
          <input type="text" placeholder="Rechercher par nom" class="form-control" v-model="filters.nom">
        </div>
        <div class="form-group col-md-4">
          <label>Role</label>
          <select class="form-control" v-model.number="filters.role_id">
            <option :value="null">Tout</option>
            <option v-for="s in params.UserRole" :value="s.id" :key="s.id">{{s.titre}}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label>Statut</label>
          <select class="form-control" v-model.number="filters.status">
            <option :value="null">Tout</option>
            <option v-for="s in params.status" :value="s.id" :key="s.id">{{s.titre}}</option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="searchInit">
            Recherche
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.myModalDeleteRef.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      items: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable:true,
        },
        {
          key: 'nom',
          label: 'Nom',
          sortable:false,
        },
        {
          key: 'prenom',
          label: 'Prenom',
          sortable:false,
        },
        {
          key: 'email',
          label: 'Email',
          sortable:false,
        },
        {
          key: 'role_id',
          label: 'Role',
          sortable:false
        },
        {
          key: 'status',
          label: 'Statut',
          sortable:false
        },
        {
          key: 'date_create',
          label: 'Date creation',
          sortable:true
        }
      ],
      datatable:{
        currentPage: 1,
        limitPage: 20,
        totalRow: 0,
        data: []
      },
      sort:{
        key: 'id',
        value: 'DESC'
      },
      filters:{
        "id": null,
        "nom":null,
        "role_id":null,
        "status":null
      },
      params:{
        role:[],
        status:[]
      },
      ready:false
    };
  },
  components: {
  },
  watch: {
  },
  beforeMount() {
    this.$store.back.ajax('/magic/all', null, (res) => {
      if(res.status === true){
        this.params = res.data;
        this.ready = true
      }
    });
  },
  methods: {
    datatableInit: function(){
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$store.back.ajax('/user/liste', params,(res) => {
        this.datatable = res;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
  },
  computed: {
  },
  mounted() {
    this.datatableInit();
  }
};
</script>
